import {throttle} from "throttle-debounce";

export default {
  data() {
    return {
      lenisScroll: null,
    };
  },
  computed: {
    showNav() {
      return this.$store.state.showNav;
    },
  },
  watch: {
    '$store.state.scrollToTarget'(newTarget) {
      if (newTarget && this.lenisScroll) {
        // Use the scrollTo method of Lenis
        this.lenisScroll.scrollTo(newTarget, {duration: 2.5}); // Lenis might have a different API for scrolling to an element
      }
    },
    '$store.state.scrollDisabled'(newState) {
      if (this.lenisScroll) {
        if (newState) {
          this.lenisScroll.stop()
        } else {
          this.lenisScroll.start()
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initLenisScroll();

      // Update Lenis instance after a delay to ensure all content is loaded
      // setTimeout(() => {
      //   if (this.lenisScroll) {
      //     // Update the Lenis instance
      //     this.lenisScroll.update();
      //   }
      // }, 3000);
    });
  },
  methods: {
    initLenisScroll() {
      // Initialize Lenis
      this.lenisScroll = this.$initLenis();

      // this.lenisScroll.on('scroll', (e) => {
      //   console.log(e)
      // })

      // Update Lenis instance after images are loaded
      // this.$imagesLoaded(scrollContainer, {background: true}, () => {
      //   this.lenisScroll.update();
      // });

      const raf = (time) => {
        this.lenisScroll.raf(time)
        requestAnimationFrame(raf)
      }
      requestAnimationFrame(raf)

      this.$store.commit('setLenisScrollInitialized', true);
    },
  },
};
